import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/masonry.css";
import { graphql, useStaticQuery } from "gatsby";
import PageLayout from "../components/pageLayout";
import { HomePageImage } from "../components/homePageImage";
import Masonry from "react-masonry-css";

const IndexPage = () => {
  const { allFile } = useStaticQuery(query);

  const content = allFile.edges
    .map((node) => node)
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  const images = content.map((edge, index) => <HomePageImage key={index} index={index} image={edge.node} />);

  return (
    <PageLayout title="Index" onShop={false}>
      <div className="ml-2 mr-2 ml-sm-4 mr-sm-4 mt-0">
        <Masonry breakpointCols={{default: 3, 500: 1}} className="masonry-grid" columnClassName="masonry-grid_column">
          {images}
        </Masonry>
      </div>
      <br />
    </PageLayout>
  );
};

export default React.memo(IndexPage);

const query = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: { regex: "content/frontpage/" }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
          fields {
            title
          }
        }
      }
    }
  }
`;
