import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import slugify from "../utils/slugify";
import { ErrorBoundary } from "./error";
import { isMobile } from "../utils/isMobile";
import Fade from "react-reveal/Fade";

export const HomePageImage = (props) => {
  const [showTitle, setShowTitle] = React.useState(false);

  let { image } = props;

  const {
    fields: { title },
    urlPrefix = "project",
  } = image;

  let picture = image?.childImageSharp.gatsbyImageData;

  let slug = `/${urlPrefix}/${slugify(title)}/`;

  return (
    <ErrorBoundary>
      <div className="mt-3 mt-sm-5" onMouseEnter={() => setShowTitle(true)} onMouseLeave={() => setShowTitle(false)}>
        <Link to={slug}>
          <GatsbyImage
            className="circle-cursor"
            image={picture}
            alt={title}
            onMouseEnter={() => setShowTitle(true)}
            onMouseLeave={() => setShowTitle(false)}
          />
        </Link>
        <Title show={showTitle} slug={slug} title={title} />
      </div>
    </ErrorBoundary>
  );
};

const Title = (props) => {
  const { show, slug, title } = props;

  if (!show && !isMobile()) {
    return <div style={{ lineHeight: 1.5, minHeight: "1.5em" }} />;
  }

  return (
    <Fade>
      <Link to={slug} className="link">
        <small className="text-dark medium">{title}</small>
      </Link>
    </Fade>
  );
};
