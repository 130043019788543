const slugify = (name) => {
  if (!name){return name}
  try {

    name = name.replace(/\s/g, "-")
      .replace(/æ/g, "ae")
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .toLowerCase();

    return name
  } catch (e) {
    console.log("SLUG ERROR", e)
    return "";
  }
};

module.exports = slugify
